import script from "./MachineSelector.vue?vue&type=script&lang=ts&setup=true"
export * from "./MachineSelector.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QField from 'quasar/src/components/field/QField.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QField,QIcon,QPopupProxy,QTree});
