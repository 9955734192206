
import { defineComponent, PropType, watch, nextTick, ref, computed } from "vue";
import moment, { Moment } from "moment";
import { CtDateTimeModeOptions } from "./models";
import QInput from 'quasar/src/components/input/QInput.js';import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';;
import { DateTimeFormats, DateTimeConversions } from "@/utils";


export default defineComponent({
  name: "CtDatetime",
  props: {
    modelValue: {
      type: Object as PropType<Moment>,
      required: true,
    },
    label: {
      type: String,
    },
    mode: {
      type: String as PropType<CtDateTimeModeOptions>,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const firstDayOfWeek = ref(1);
    const inputRef = ref<QInput>();
    const dateProxyRef = ref<QPopupProxy>();
    const timeProxyRef = ref<QPopupProxy>();
    let ignoreManualInput = false;
    let forceEmpty = ref(false);

    // Computed

    const model = computed(() => {
      if (forceEmpty.value) return "";
      return DateTimeConversions.momentToDateHM(moment(props.modelValue));
    });
    const formats = computed(() => DateTimeFormats);
    const mask = computed(() => {
      if (props.mode === CtDateTimeModeOptions.DateTime)
        return DateTimeFormats.dateHMMask;
      else if (props.mode === CtDateTimeModeOptions.Day)
        return DateTimeFormats.dateMask;
      else if (props.mode === CtDateTimeModeOptions.Week)
        return DateTimeFormats.dateMask;
      else if (props.mode === CtDateTimeModeOptions.Month)
        return DateTimeFormats.monthMask;
      else return "";
    });
    const hint = computed(() => {
      if (props.mode === CtDateTimeModeOptions.Day)
        return DateTimeConversions.momentToDateHuman(props.modelValue);
      if (props.mode === CtDateTimeModeOptions.Week)
        return `Week ${props.modelValue.isoWeek()}`;
      if (props.mode === CtDateTimeModeOptions.Month)
        return DateTimeConversions.momentToMonthHuman(props.modelValue);
      return "";
    });

    const withPlusMinusButtons = computed(() => {
      return (
        props.mode === CtDateTimeModeOptions.Day ||
        props.mode === CtDateTimeModeOptions.Week ||
        props.mode === CtDateTimeModeOptions.Month
      );
    });

    const withTimePicker = computed(() => {
      return props.mode === CtDateTimeModeOptions.DateTime;
    });

    // Watchers
    watch(
      () => props.mode,
      (newMode: CtDateTimeModeOptions) => {
        if (newMode === CtDateTimeModeOptions.Day) {
          const startOfDay = moment(props.modelValue).startOf("day");
          onNewValue(startOfDay, true);
        } else if (newMode === CtDateTimeModeOptions.Week) {
          const startOfWeek = moment(props.modelValue).startOf("isoWeek");
          onNewValue(startOfWeek, true);
        } else if (newMode === CtDateTimeModeOptions.Month) {
          const firstOfMonth = moment(props.modelValue).startOf("month");
          onNewValue(firstOfMonth, true);
        } else {
          onNewValue(moment(props.modelValue), true);
        }
      }
    );

    // private methods
    const onNewValue = async (
      newValue: Moment,
      newIgnoreManualInput: boolean
    ) => {
      ignoreManualInput = newIgnoreManualInput;

      if (!newValue.isValid()) return;

      if (!props.modelValue.isSame(newValue)) {
        emit("update:modelValue", newValue);
      }

      if (!ignoreManualInput) return;

      await nextTick();
      forceEmpty.value = true;
      await nextTick();
      forceEmpty.value = false;

      ignoreManualInput = false;
    };

    const increase = () => {
      const val = moment(props.modelValue);
      if (props.mode === CtDateTimeModeOptions.Day) val.add(1, "day");
      if (props.mode === CtDateTimeModeOptions.Week) val.add(7, "days");
      if (props.mode === CtDateTimeModeOptions.Month) val.add(1, "month");

      onNewValue(val, true);
    };

    const decrease = () => {
      const val = moment(props.modelValue);
      if (props.mode === CtDateTimeModeOptions.Day) val.add(-1, "day");
      if (props.mode === CtDateTimeModeOptions.Week) val.add(-7, "days");
      if (props.mode === CtDateTimeModeOptions.Month) val.add(-1, "month");

      onNewValue(val, true);
    };

    const onManualEdit = (newValue: string | number | null) => {
      if (typeof newValue !== "string")
        return;
      if (ignoreManualInput) return;
      const converted = DateTimeConversions.dateHMToMoment(newValue);
      if (converted.isSame(props.modelValue)) return;
      onNewValue(converted, false);
    };

    const onDateSelected = (newValue: string) => {
      if (dateProxyRef.value === null) return;
      (dateProxyRef.value as QPopupProxy).hide();
      const date = DateTimeConversions.dateHMToMoment(newValue);
      onNewValue(date, true);
    };

    const onTimeSelected = (newValue: string | null) => {

      if (newValue == null || timeProxyRef.value === null) return;

      const date = DateTimeConversions.dateHMToMoment(newValue);
      onNewValue(date, true);
    };

    const dateOptionsHandler = (value: string) => {
      const date = moment(value, "YYYY/MM/DD");

      if (props.mode === CtDateTimeModeOptions.Week)
        return date.day() === firstDayOfWeek.value;
      else if (props.mode === CtDateTimeModeOptions.Month)
        return date.date() === 1;
      else return true;
    };

    return {
      model,
      inputRef,
      mask,
      hint,
      onManualEdit,
      withPlusMinusButtons,
      decrease,
      increase,
      dateProxyRef,
      ignoreManualInput,
      firstDayOfWeek,
      formats,
      onDateSelected,
      dateOptionsHandler,
      withTimePicker,
      timeProxyRef,
      onTimeSelected,
    };
  },
});
