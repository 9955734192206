import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_time = _resolveComponent("q-time")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_input, {
      ref: "inputRef",
      "model-value": _ctx.model,
      class: "full-width",
      filled: "",
      debounce: "500",
      dense: "",
      mask: _ctx.mask,
      hint: _ctx.hint,
      label: _ctx.label,
      "onUpdate:modelValue": _ctx.onManualEdit
    }, _createSlots({
      prepend: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: "event",
          class: "cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_popup_proxy, {
              ref: "dateProxyRef",
              "transition-show": "scale",
              "transition-hide": "scale",
              onBeforeShow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ignoreManualInput = true)),
              onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ignoreManualInput = false))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_date, {
                  "today-btn": "",
                  "model-value": _ctx.model,
                  "first-day-of-week": _ctx.firstDayOfWeek,
                  mask: _ctx.formats.dateHM,
                  options: _ctx.dateOptionsHandler,
                  "onUpdate:modelValue": _ctx.onDateSelected
                }, null, 8, ["model-value", "first-day-of-week", "mask", "options", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        })
      ]),
      _: 2
    }, [
      (_ctx.withPlusMinusButtons)
        ? {
            name: "after",
            fn: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                dense: "",
                icon: "remove",
                onClick: _ctx.decrease
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                flat: "",
                dense: "",
                icon: "add",
                onClick: _ctx.increase
              }, null, 8, ["onClick"])
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.withTimePicker)
        ? {
            name: "append",
            fn: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "access_time",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    ref: "timeProxyRef",
                    "transition-show": "scale",
                    "transition-hide": "scale"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_time, {
                        format24h: "",
                        "model-value": _ctx.model,
                        mask: _ctx.formats.dateHM,
                        "onUpdate:modelValue": _ctx.onTimeSelected,
                        onBeforeShow: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ignoreManualInput = true)),
                        onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ignoreManualInput = false))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["model-value", "mask", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["model-value", "mask", "hint", "label", "onUpdate:modelValue"])
  ]))
}